<template>
  <button class="small-btn" :class="{active: active}" :disabled="disabled" @click.stop="$emit('click')">
    <img :src="img"/>
  </button>
</template>

<script>

export default {
  name: 'SmallBtn',
  props: {
    img: {
      requred: true
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
$BtnSize: px2rem(36);
$ImgSize: px2rem(22);
$BtnPad: calc(($BtnSize - $ImgSize) / 2);
* {
  box-sizing: border-box;
}
.small-btn {
  border-radius: 0.5rem;
  padding: $BtnPad;
  height: $BtnSize;
  width: $BtnSize;
  img {
    height: $ImgSize;
    width: $ImgSize;
  }
  &:hover {
    background-color: $color_FFF_10;
  }
  &.active {
    background-color: $color_FFF_50;
  }
  &:disabled {
    @include disabled;
    &:hover {
      background-color: unset;
    }
  }
}
</style>
