<template>
  <div class="wrap-expand-line">
    <div class="line"></div>
    <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t(content)" placement="top">
      <button class="toggle-btn" :disabled="disabled" @click="$emit('click')">
        <img :class="{ expand }" src="@/assets/icons/calling-glist-dn.svg" />
      </button>
    </el-tooltip>
  </div>
</template>

<script>

export default {
  name: 'ExpandLine',
  props: {
    expand: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  components: {},
  computed: {
    content() {
      return this.expand ? 'collapse' : 'expand' // 需要反向處理
    }
  },
  watch: {},
  methods: {},
  mounted() {},
  // beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$ExpandLineMaxH: 24;
$ExpandLineIconH: px2rem(12);
$ExpandLineH: 2;

* {
  box-sizing: border-box;
}
.wrap-expand-line {
  position: relative;
  display: flex;
  flex-direction: column;
  height: px2rem($ExpandLineMaxH);

  .line {
    margin: px2rem(calc(($ExpandLineMaxH - 2) / 2)) 0;
    height: calc($ExpandLineH * 1px);
    background-color: $color_FFF_20;
  }
  .toggle-btn {
    position: absolute;
    right: px2rem($ExpandLineMaxH);
    top: 0;
    border-radius: 50%;
    height: px2rem($ExpandLineMaxH);
    width: px2rem($ExpandLineMaxH);
    background-color: $color_4A5C78;
    img {
      height: $ExpandLineIconH;
      width: $ExpandLineIconH;
      transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);
      &.expand {
        transform: rotate(180deg);
      }
    }

    &:not(:disabled):hover {
      background-color: $color_6E7D93;
    }

    &:disabled {
      img {
        @include disabled;
      }
    }
  }
}
</style>
